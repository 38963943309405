<template>
  <section class="main">
    <div class="question">
      <div v-if="showQuestion">
        <span v-if="question.material">
          <span v-html="question.material"></span>
          <br />
        </span>

        {{ qIndex }}.<span v-html="question.title"></span> <br />
        <span v-html="question.content"></span>
      </div>
      <div class="title" v-if="showAnswer">
        {{ qIndex }}.<span v-html="question.refAnswer"></span> <br />
        <span v-html="question.solution"></span>
      </div>
    </div>
  </section>
</template>

<script>
import { mdUtil } from "@/util/mdUtil";

export default {
  props: {
    tikuQuestion: Object,
    showAnswer: Boolean,
    showQuestion: Boolean,
    qIndex: Number,
  },
  components: {},
  data() {
    return {
      showLatex: 0,
      question: {
        title: "",
        content: "",
        source: "",
        refAnswer: "",
        solution: "",
        material: "",
      },
    };
  },
  methods: {
    render(item) {
      if (item && item.type == 0) {
        this.renderChoice(item);
      } else if (item && item.type == 1) {
        this.renderBlank(item);
      }
    },
    renderChoice(item) {
      // choice
      this.question.title = mdUtil.richTextUnitToHtml(item.choiceQuestion.title);
      this.question.title = mdUtil.removeHtmlTag(this.question.title);

      this.question.content = mdUtil.stringMdToHtml(item.choiceQuestion.content); // 选项的转换

      this.question.source = item.choiceQuestion.source;

      let refAnswer = "" + item.choiceQuestion.refAnswer.map((x) => String.fromCharCode(65 + parseInt(x))).join(",");
      this.question.refAnswer = refAnswer;
      // if (this.showQuestion == false) {
      //   this.question.refAnswer = this.qIndex + "." + this.question.refAnswer;
      // }

      this.question.solution = mdUtil.richTextUnitToHtml(item.choiceQuestion.solution);
      this.question.solution = this.question.solution.replaceAll("<p>", "");
      this.question.solution = this.question.solution.replaceAll("</p>", "<br/>");
      //this.question.solution = "【解析】" + this.question.solution;

      let materialList = item.choiceQuestion.tikuMaterialList;
      if (materialList && materialList.length > 0) {
        let bigMaterial = "";
        for (let i = 0; i < materialList.length; i++) {
          bigMaterial += mdUtil.richTextUnitToHtml(materialList[i].content) + "<br />";
        }
        item.material = bigMaterial;
        this.question.material = item.material;
      }
    },
    renderBlank(item) {
      // blank
      this.question.title = mdUtil.richTextUnitToHtml(item.blankQuestion.title);
      this.question.title = mdUtil.removeHtmlTag(this.question.title);

      this.question.content = "";

      this.question.source = item.blankQuestion.source;

      let materialList = item.blankQuestion.tikuMaterialList; // 材料是列表
      if (materialList && materialList.length > 0) {
        let bigMaterial = "";
        for (let i = 0; i < materialList.length; i++) {
          bigMaterial += mdUtil.richTextUnitToHtml(materialList[i].content) + "<br />";
        }
        item.material = bigMaterial;
        this.question.material = item.material;
        //&nbsp;
        // this.question.material = this.question.material.replaceAll("<p>", "");
        // this.question.material = this.question.material.replaceAll("</p>", "<br/>");
      }
      let refAnswer = item.blankQuestion.refAnswer;
      this.question.refAnswer = "参考答案：" + mdUtil.richTextUnitToHtml(refAnswer);
      // if (this.showQuestion == false) {
      //   this.question.refAnswer = this.qIndex + "." + this.question.refAnswer;
      // }

      this.question.solution = mdUtil.richTextUnitToHtml(item.blankQuestion.solution);
      this.question.solution = this.question.solution.replaceAll("<p>", "");
      this.question.solution = this.question.solution.replaceAll("</p>", "<br/>");
      //this.question.solution = "【解析】" + this.question.solution;
    },
  },
  mounted() {
    console.log("mounted", this.tikuQuestion);
    if (this.tikuQuestion) {
      this.render(this.tikuQuestion);
    }
  },
  filters: {},
  watch: {
    tikuQuestion: function(newValue) {
      console.log("watch", newValue);
      this.render(newValue);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 700px;
  text-align: left;
  .question {
    display: flex;
    flex-direction: column;
    img {
      vertical-align: middle;
    }
  }
}
</style>

<style>
.latex-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.latex-inline {
  vertical-align: middle;
}
</style>
